import * as React from "react"

import Seo from "../components/seo"

const IndexPage = () => (
  <div>
    <Seo title="Home" />
    Content to go here
  </div>
)

export default IndexPage
